.ListDetail-zone {
  display: flex;
  height: auto;
  width: 100%;

  .ListDetail-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    padding: 3.5% 0%;
    .relatin-label-container {
      display: flex;
      height: auto;
      width: 100%;
      .relatin-label {
        display: flex;
        padding-left: 1%;
        padding-right: 4%;
        padding-top: 1.5%;
        padding-bottom: 1.5%;
        font-size: small;
        border-top-right-radius: 9px;
        background-color: var(---settingCollapseColor);
      }
    }
    .relation-body {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
      min-height: 30px;
      .toolbar-container {
        display: flex;
        height: calc((100vh - 10.5rem) * 0.9 * 0.82 * 0.07);
        max-height: calc((100vh - 10.5rem) * 0.9 * 0.82 * 0.07);
        min-height: calc((100vh - 10.5rem) * 0.9 * 0.82 * 0.07);
        justify-content: center;
        align-items: center;
        color: #6f53a3;
        padding-left: 2%;
        padding-right: 2%;
        margin-top: 1%;
        margin-bottom: 1%;
      }
    }
    .list-empty {
      display: flex;
      height: auto;
      width: 100%;
      padding: 6px 0;
      justify-content: center;
      align-items: center;
      color: rgba(0, 0, 0, 0.5);
      font-size: 14px;
      font-weight: 600;
    }
  }
  .relationshipModal {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    .ReHeader {
      display: flex;
      width: 100%;
      height: 8%;
      max-height: 8%;
      min-height: 8%;
      justify-content: flex-end;
      align-items: flex-end;
      .ReLabel {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 70%;
        max-height: 70%;
        min-height: 70%;
        height: 100%;
        padding-left: 5%;
        padding-right: 5%;
        background-color: white;
        border-top-left-radius: 12px;
        font-weight: 700;
        background-color: var(---primary);
        color: white;
      }
    }
    .ReContent {
      display: flex;
      width: 100%;
      height: 92%;
      max-height: 92%;
      min-height: 92%;
      background-color: white;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 36px;
      border-bottom-right-radius: 36px;
      justify-content: center;
      align-items: center;
    }
  }
}
