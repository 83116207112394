.attribute-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  // background-color: red;
  width: 100%;
  padding: 6px 3px;
  .att-label {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: var(---label);
    font-weight: 500;
    gap: 3px;
    .hidden-label {
      font-weight: 800;
      font-size: 10px;
    }
  }
  .att-value {
    color: var(---textColor);
  }
  .withoutLabel {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 3px;
    .hidden-label {
      font-weight: 800;
      font-size: 10px;
    }
  }
  // &:hover {
  //   background-color: #3663d633;
  // }
}

.att {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  height: 22px;
  font-size: 12px;
  background-color: white;
  .att-name {
    color: #6f53a3;
  }
}
.classification {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 22px;
  font-size: 12px;
  background-color: white;
  .att-name {
    color: #6f53a3;
  }
}
.att-withLabel-profile {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  min-height: 50px;
  // height: auto;
  font-size: 12px;
  margin-bottom: 10px;
  // padding: 5px 5px 5px 5px;
  padding-left: 15px;
  gap: 1.5px;
  align-self: stretch;
  border-radius: 9px;
  border: 1px solid #eee;
  background-color: white;

  // background: #fff;
  .att-name {
    color: #aaa;
    font-family: Hanken Grotesk;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .att-data {
    color: #000;
    font-family: Hanken Grotesk;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
.att-withLabel {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 22px;
  font-size: 12px;
  background-color: white;
  .att-name {
    color: #6f53a3;
  }
}

.hovered {
  &:hover {
    background-color: #3663d633;
  }
}
.row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 3%;
  height: 100%;
  padding-left: 3%;
  padding-right: 3%;
  background-color: transparent;
}
.column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-height: 42px;
  gap: 1%;
  padding-left: 3%;
  padding-right: 3%;
  background-color: transparent;
}
