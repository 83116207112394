.body-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: max-content;
  background-color: var(---listBg);
  border-bottom: 1px solid #eeeeee;
  .body {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 100%;
    width: 98%;
    padding-top: 3%;
    padding-bottom: 3%;
    padding-left: 5%;
    padding-right: 5%;
    min-height: 30px;
    // background-color: aquamarine;

    .body-header {
    }
    .body-footer {
    }
  }
  .drop-body-zone {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: fit-content;
    border-bottom: 1.5px solid var(---separatorColor);
    .body-thumb {
      display: flex;
      height: auto;
      width: 9px;
    }
    .body-att {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: calc(100% - 9px);
      padding: 6px 8px 6px 8px;
      gap: 6px;
      .empty-body {
        display: flex;
        flex-direction: row;
        justify-content: center;
        justify-items: center;
        align-items: center;
        font-size: 14px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.5);
      }
      .header-body {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-start;
        height: auto;
        width: 100%;

        .body-header {
          display: flex;
          width: 100%;
        }
        .swipe-btn {
          display: flex;
          height: 25px;
          flex-direction: row;
          width: 25px;
          justify-content: center;
          align-items: center;

          .open-swipe-icon {
            display: flex;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #6f53a3;
          }
        }
      }
      .body-preview-image {
        display: flex;
        width: 100%;
        border-radius: 5px;
      }
      .drop-attributes-zone {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
        padding: 0 4px;
        gap: 4px;
      }
    }
  }
  .empty-body {
    height: 90px;
  }
  .thumb-style {
    display: flex;
    height: max-content;
    width: 5px;
  }
  .point-display-swipe {
    border-radius: 500px;
    background-color: gray;
    bottom: 0px;
    align-self: flex-start;
  }
}
.bodyStructure-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: max-content;
  background-color: var(---listBg);
  border-bottom: 1px solid #eeeeee;
  .drop-body-zone {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: fit-content;
    border-bottom: 1.5px solid var(---separatorColor);
    padding: 10px 0;
    .noImage {
      width: 30%;
      display: flex;
      align-items: center;
      justify-content: center;
      .hovered {
        &:hover {
          background-color: #3663d633;
        }
      }
    }
    .body-att {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: calc(100% - 9px);
      padding: 6px 8px 6px 8px;
      gap: 6px;
      .empty-body {
        display: flex;
        flex-direction: row;
        justify-content: center;
        justify-items: center;
        align-items: center;
        font-size: 14px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }
}
.bodyStructure-container-card {
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(72, 72, 72, 0.15);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 0;
  border: 1px solid #eee;
  .drop-body-zone {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    align-items: center;
    padding: 0;
    padding-top: 10px;

    justify-content: center;
    border-bottom: 0;
    .noImage {
      height: 100%;
      width: 110px;
      display: flex;
      padding: 10px;
      justify-content: center;
      .hovered {
        &:hover {
          background-color: #3663d633;
        }
      }
    }
    .body-att {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: calc(100% - 9px);
      padding: 6px 8px 6px 8px;
      gap: 0px;
      .empty-body {
        display: flex;
        flex-direction: row;
        justify-content: center;
        justify-items: center;
        align-items: center;
        font-size: 14px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }
}
.body-container-card {
  border-radius: 5px;
  box-shadow: 0 0 8px rgba(72, 72, 72, 0.3);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .drop-body-zone {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: fit-content;
    border-bottom: 1.5px solid var(---separatorColor);
    padding: 10px 0;
    .noImage {
      height: 100%;
      width: 30%;
      display: flex;

      justify-content: center;
      .hovered {
        &:hover {
          background-color: #3663d633;
        }
      }
    }
    .body-att {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: calc(100% - 9px);
      padding: 6px 8px 6px 8px;
      gap: 6px;
      .empty-body {
        display: flex;
        flex-direction: row;
        justify-content: center;
        justify-items: center;
        align-items: center;
        font-size: 14px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }
  // .thumb-style {
  //   height: 100%;
  //   min-height: 100% !important;
  //   width: 5px;
  //   background-color: red;
  // }
  .body {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 100%;
    width: 98%;
    padding-top: 3%;
    padding-bottom: 3%;
    padding-left: 5%;
    padding-right: 5%;
    min-height: 30px;
    // background-color: aquamarine;

    .body-header {
    }
    .body-footer {
    }
  }
}

.hovered {
  &:hover {
    border: 0px solid transparent !important;
    background-color: #3663d633;
    .point-display-swipe {
      width: 7px;
      height: 7px;
      border-radius: 500px;
      background-color: gray;
      bottom: 0px;
      align-self: flex-start;
    }
  }
}

/* Your existing styles for the input container */
.floating-label-style {
  background-color: #fff;
  border-color: #e2e2e2;
  border-radius: 9px;
  border-width: 1px;
  border-style: solid;
  width: 100%;
  padding: 5px;
  outline: #6f53a3;
}

/* Style for the placeholder text */
.floating-label-style::placeholder {
  color: red; /* Change the color as needed */
  font-size: 12px; /* Change the font size as needed */
}
.floating-label-style label {
  color: #aaa; /* Change the color as needed */
  font-size: 10px; /* Change the font size as needed */
  width: 100%;
  padding-left: 5px;
  padding-bottom: 2px;
  width: auto;
}

.btns-container {
  height: 15%;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px -5px 25px 0px rgba(34, 34, 34, 0.15);
  border-radius: 12px 12px 0px 0px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  .btn-cancel {
    width: 35%;
    height: 50%;
    border-radius: 9px;
    border-width: 1px;
    border-style: solid;
    border-color: #eee;
    background: #fff;
    font-size: 12px;
  }
  .btn-submit {
    width: 35%;
    height: 50%;
    border-radius: 9px;
    background: #6a549e;
    border-width: 0px;
    color: #fff;
    font-size: 12px;
  }
}

.hovered-selected {
  background-color: #6f53a340;

  border-color: #3663d6;
  border-radius: 6px;
}

.preview-image-display {
  min-height: 30px;
  width: 95%;
  display: flex;
  align-self: center;
}
