.form-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  align-items: center;

  .close-form {
    display: flex;
    width: 100%;
    height: 8%;
  }
  .form-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 92%;
    max-height: 92%;
    min-height: 92%;
    background-color: var(---listBg);
    justify-content: flex-start;
    align-items: center;
    border-radius: 9px 9px 36px 36px;

    .form-title {
      display: flex;
      width: 100%;
      height: 6%;
      justify-content: center;
      align-items: center;
      font-size: small;
    }
    .form-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      height: 84%;
      max-height: 84%;
      overflow-y: auto;

      .title-map {
        display: flex;
        width: 100%;
        height: calc((100vh - 10.5rem) * 0.05);
        max-height: calc((100vh - 10.5rem) * 0.05);
        min-height: calc((100vh - 10.5rem) * 0.05);
        background-color: #e3e5e6;
      }
      .geomodule-container {
        width: 100%;
        height: calc((100vh - 10.5rem) * 0.3);
        max-height: calc((100vh - 10.5rem) * 0.3);
        min-height: calc((100vh - 10.5rem) * 0.3);
      }
      .form-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
      }
      .form-fileds {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        height: auto;
        padding-left: 3%;
        padding-right: 3%;
      }
    }
    .form-body::-webkit-scrollbar {
      display: none;
    }
    .form-buttons {
      display: flex;
      flex-direction: row;
      width: 100%;
      height: 11%;
      min-height: 11%;
      justify-content: center;
      align-items: flex-start;
      gap: 4%;
      padding: 6px 6px 0px 6px;
      padding-bottom: calc(4% + 3px);
      background-color: var(---listBg);
      border-radius: 12px 12px 36px 36px;
      box-shadow: 0px -4px 12px #22222230;

      .cancelBtn {
        display: flex;
        width: 44%;
        height: 100%;
        border-radius: 9px;
        justify-content: center;
        align-items: center;
        background-color: var(---listBg);
        border: 1px solid var(---cancelButton);
        color: var(---textColor);
      }
      .SubmitBtn {
        display: flex;
        width: 44%;
        height: 100%;
        color: white;
        border-radius: 9px;
        justify-content: center;
        align-items: center;
        background-color: var(---primary);
      }
    }
  }
  .empty-form-content {
    display: flex;
    flex-direction: column;
    width: 99.8%;
    height: 92%;
    max-height: 92%;
    min-height: 92%;
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
    justify-content: flex-start;
    align-items: center;
    border-radius: 6px 6px 50px 50px;
    background-color: antiquewhite;

    .form-title {
      display: flex;
      width: 100%;
      height: 6%;
      justify-content: center;
      align-items: center;
      font-size: small;
    }
    .form-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      height: 84%;
      max-height: 84%;
      overflow-y: auto;
      .title-map {
        display: flex;
        width: 100%;
        height: calc((100vh - 10.5rem) * 0.05);
        max-height: calc((100vh - 10.5rem) * 0.05);
        min-height: calc((100vh - 10.5rem) * 0.05);
        background-color: #e3e5e6;
      }
      .geomodule-container {
        width: 100%;
        height: calc((100vh - 10.5rem) * 0.3);
        max-height: calc((100vh - 10.5rem) * 0.3);
        min-height: calc((100vh - 10.5rem) * 0.3);
      }
      .form-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: calc((100vh - 10.5rem) * 0.063);
        max-height: calc((100vh - 10.5rem) * 0.063);
        min-height: calc((100vh - 10.5rem) * 0.063);
        margin-bottom: 2%;
      }
      .form-fileds {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        height: auto;
        padding-left: 3%;
        padding-right: 3%;
      }
    }
    .form-body::-webkit-scrollbar {
      display: none;
    }
    .form-buttons {
      display: flex;
      flex-direction: row;
      width: 100%;
      height: 11%;
      min-height: 11%;
      justify-content: center;
      align-items: flex-start;
      gap: 4%;
      padding-top: 2%;
      padding-bottom: 4%;
      // border-radius: 0px 0px 50px 50px;
      background-color: #a35f53;

      .cancelBtn {
        display: flex;
        width: 44%;
        height: 100%;
        border-radius: 9px;
        justify-content: center;
        align-items: center;
        background-color: var(---cancelButtons);
      }
      .SubmitBtn {
        display: flex;
        width: 44%;
        height: 100%;
        color: white;
        border-radius: 9px;
        justify-content: center;
        align-items: center;
        background-color: var(---primary);
      }
    }
  }
}
