.interceptorConatiner {
  display: flex;

  flex-grow: 1;
  position: relative;

  span.Resizer.vertical {
    background: #eeeeee;
    width: 4px;
    cursor: col-resize;
  }

  .button-reset {
    background-color: #4c36a5;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 2px;
    opacity: 0.8;
    padding-bottom: 2px;
    color: white;
  }

  //hover background color opacity down
  .button-reset:hover {
    background-color: #4c36a5;
    opacity: 1;
  }

  .split-pane-container {
    display: flex;
    // background: #f0f0f0;
    // margin-left: 20rem; /* Added margin to avoid overlapping with the sidebar */
    flex-grow: 1;
    position: relative;

    border-left: 1px solid #eee;
    height: 100vh; /* Ensure it takes full viewport height */
  }

  .pane {
    overflow: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .requests-table-container {
    padding: 10px;
    .requests-table-heaeder {
      position: sticky;
      z-index: 998;
      top: 6.4rem;
      display: grid;
      grid-template-columns: 10% 30% 45% 15%;
      width: 100%;
      gap: 10px;
      font-weight: bold;
      padding: 10px;
      border-bottom: 1px solid #eee;
      background-color: #f5f5f5;

      .table-keys {
        font-size: 15px;
        font-weight: 600;
        color: #39354a;
      }
    }
  }

  .requests-display {
    display: grid;
    grid-template-columns: 10% 30% 45% 15%;
    width: 100%;
    gap: 10px;
  }

  .request-header-container {
    position: sticky;
    top: 0;
    padding: 10px;
    z-index: 9999;
    background-color: #ffffff;
    gap: 6px;
    display: flex;
    flex-direction: column;
    margin-bottom: 6px;
    .request-header {
      display: flex;
      justify-content: space-between;
      justify-items: center;

      .buttons-requests {
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }
    .search-requests {
      position: relative;
      .search-input {
        width: 100%;
        padding: 8px;
        border: 1px solid #eee;
        border-radius: 2px;
        padding-left: 35px;
        background-color: #f8f8f8;
      }

      // .search-input::placeholder {
      //   padding-left: 25px;
      // }
      .search-icon {
        color: #989898;
        position: absolute;
        left: 5px;
        top: 20%;
        color: #4c36a5;
      }
      .searched-requests {
        position: absolute;
        right: 5px;
        top: 15%;
        display: flex;
        align-items: center;
        gap: 5px;
        .requests-result {
          color: #4c36a5;
          font-size: 20px;
          font-weight: 500;
        }

        .requests-span {
          color: #7c7987;
          font-size: 15px;
          font-weight: 300;
        }
      }
    }
  }

  .request-host-path {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .response-options-container {
    .response-options-header {
      display: flex;
      cursor: pointer;
      justify-content: space-between;
    }
    .response-request-body {
      display: flex;
      justify-content: space-between;
    }

    border: 1px solid #eee;
    border-radius: 2px;
    background-color: #fafafb;
    padding: 10px;
    .response-key {
      color: #39354a;
      font-weight: 500;
      font-size: 20px;
      display: flex;
      align-items: center;
      gap: 10px;
      .request-method {
        color: #4c36a5;
        background-color: rgba(76, 54, 165, 0.2);
        border: 1px solid #4c36a5;
        padding-left: 10px;
        padding-right: 10px;
        text-transform: uppercase;

        border-radius: 72px;
      }
    }
    .response-area-keys {
      color: rgba(112, 112, 112, 1);
    }
  }

  .show-more {
    color: rgba(170, 170, 170, 1);
    font-weight: 400;
    cursor: pointer;
    //text underline*
    text-decoration: underline;
  }

  .json-container {
    max-height: 500px;
    overflow: scroll;
    background-color: #fff;
    border-radius: 2px;
    padding: 5px;
    white-space: pre-wrap;
    word-break: break-all;
  }
  .size-value {
    color: rgba(57, 53, 74, 1);
    font-size: 16px;
    font-weight: 400;
  }

  .border-of-request {
    border-right: 1px solid #eee;
  }
  .padding-of-request {
    padding: 5px;
  }

  .selection-mode {
    cursor: pointer;
    padding: 10px;
    display: flex;
    align-items: center;
    // justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
    gap: 10px;

    border-bottom: 1px solid #eeeeee;
  }

  .selection-mode:hover {
    background-color: #4c36a5;
    color: #ffffff;
  }

  .selected-request {
    padding: 10px;
    display: flex;
    gap: 10px;
    flex-direction: column;
  }
}
